<template>
    <div class="w-100 width">
        <HeaderMenu/>
        <b-container class="mt-3">
            <router-view/>
        </b-container>
    </div>
</template>

<script>
import HeaderMenu from "@/components/header/Header";

export default {
  name: "AppLayout",
  components: { HeaderMenu }
};
</script>

<style scoped>
.width{
    width: 100vw !important;
}
</style>
