<template>
    <div class="w-50 mt-5 ml-auto mr-auto">
        <div class="logo-container">
            <img class="d-block m-auto logo-sticky w-75" src="@/assets/images/dark_logo.png">
        </div>
        <h2 class="mb-5 mt-4">Reset Password</h2>
        <b-form @submit="onSubmit" @reset="onReset">
            <b-form-group>
                <b-form-input type="password"
                              v-model="form.password"
                              required
                              placeholder="Reset Password">
                </b-form-input>

            </b-form-group>
            <b-form-group>
                <b-form-input type="password"
                              v-model="form.repeatPassword"
                              required
                              placeholder="Confirm Password">
                </b-form-input>
            </b-form-group>
            <b-button type="submit"
                      ref="submit"
                      class="btn-submit btn-theme"
                      variant="primary">Reset Password
            </b-button>
        </b-form>
    </div>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      form: {
        password: "",
        repeatPassword: ""
      }
    };
  },
  created() {
    if (this.$store.state.user !== null) {
      this.$store.commit("resetState");
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$store.dispatch("reset").then(() => {});
        })
        .catch(() => {});
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      let btn_ref = this.$refs.submit;
      this.$disabled_btn(btn_ref);

      let formData = new FormData();
      formData.append("password", this.form.password);
      formData.append("password_confirmation", this.form.repeatPassword);
      formData.append("token", this.$route.params.token);

      this.$store
        .dispatch("resetpassword", formData)
        .then(response => {
          btn_ref.removeAttribute("disabled");
          btn_ref.innerHTML = btn_ref.getAttribute("original-text");
          this.form = { password: "", repeatPassword: "" };
          this.$notify("Success!", response.data.error_msg, "success");
          setTimeout(() => this.$router.push("/"), 3000);
        })
        .catch(error => {
          btn_ref.removeAttribute("disabled");
          btn_ref.innerHTML = btn_ref.getAttribute("original-text");
          if (error.response.data.error === true) {
            this.$notify("Error!", error.response.data.error_msg, "danger");
          }
        });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form.password = "";
      this.form.confirm_password = "";
    }
  }
};
</script>
>

<style scoped>
</style>
