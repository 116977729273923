import Vue from "vue";
import Vuex from "vuex";
import axios from "./assets/js/axios";

Vue.use(Vuex);

const initialState = () => ({
  user: null,
  companies: [],
  all_companies: [],
  pagination : [],
  users: [],
  projects: [],
  screenshots: [],
  CustomLog: [],
  dashboard_widgets: [],
  selected_company_id: null,
  isBusy: false,
  show_loader: false,
  permissions: null
});

const mutations = {
  set_user_permissions(state, data) {
    state.permissions = data;
  },
  reset_user_permissions(state) {
    state.permissions = [];
  },
  set_all_companies(state, data) {
    state.all_companies = data;
  },
  set_pagination(state, data){
      state.pagination = data;
  },
  set_user(state, data) {
    state.user = data;
  },
  reset_user(state) {
    state.user = null;
  },

  set_companies(state, data) {
    state.companies = data;
  },
  set_users(state, data) {
    state.users = data;
  },
  set_users_logs(state, data) {
    state.users_logs = data;
  },
  set_admins(state, data) {
    state.admins = data;
  },
  set_admin_users_logs(state, data) {
    state.admins_logs = data;
  },
  set_roles_permissions(state, data) {
    state.roles_permissions = data;
  },
  set_roles_list(state, data) {
    state.roles_list = data;
  },
  set_admin_roles_list(state, data) {
    state.admin_roles_list = data;
  },
  set_screenshots(state, data) {
    state.screenshots = data;
  },
  set_projects(state, data) {
    state.projects = data;
  },
  set_custom_log(state, data) {
    state.CustomLog = data;
  },
  set_dashboard(state, data) {
    state.dashboard_widgets = data;
  },
  set_busy(state, data) {
    state.isBusy = data;
  },
  set_selected_company_id(state, data) {
    state.selected_company_id = data;
  },
  toggle_loader(state, bool) {
    state.show_loader = bool;
  },

  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};
const actions = {
  verify(NULL, data) {
    return axios.get("verify/admin/" + data);
  },
  updatePassword(NULL, data) {
    return axios.post("update-password-admin", data);
  },
  emailDetails(NULL, data) {
    return axios.post("/user-notification-details", data);
  },
  // Update request for active status of a company
  companyActiveStatus(NULL, data){
    return axios.post("update-active-status", data);
  },
  async login({ commit, dispatch }, data) {
    commit("toggle_loader", true);
    await axios.get("/api/csrf-cookie");
    return axios.post("auth/adminLogin", data).then(response => {
      dispatch("setCurrentUser", response);
      dispatch("setUserPermission", response);
      return response;
    });
  },
  async dailyTeenMinuteVideo({ commit, dispatch }, data) {
    commit("toggle_loader", true);
    await axios.get("/api/csrf-cookie");
    return axios.post("daily-video-screenshot", data).then(response => {
      return response;
    });
  },
  setCurrentUser({ commit }, response) {
    commit("set_user", response.data.auth_user);
  },
  setUserPermission({ commit }, response) {
    let all_permission = [];
    response.data.permissions.map(function(per) {
      all_permission.push(per.name);
    });
    commit("set_user_permissions", all_permission);
  },
  reset({ commit }) {
    commit("reset_user");
    commit("toggle_loader", false);
    localStorage.clear();
    return Promise.resolve();
  },

  forgotPassword(NULL, data) {
    return axios.post("auth/admin-forgot-password", data);
  },

  getCompanies({ commit, dispatch }, data) {
    commit("set_busy", true);
    commit("toggle_loader", true);
    return axios
      .post("companies", data)
      .then(response => {
        commit("set_companies", response.data.companies);
        commit("set_pagination",response.data.pagination);
        setTimeout(() => commit("set_busy", false), 3000);
      })
      .catch(error => {
        commit("set_busy", false);
        commit("set_companies", []);
        commit("set_pagination",[]);

          if (error.response) {
          if (error.response.data.message === "Unauthenticated.") {
            dispatch("reset").then(() => {
              commit("resetState");
              location.reload(true);
            });
          }
        }
      });
  },

  csvCompany({ commit, dispatch },data) {
      return axios.post("export-company",data);
      // return axios
      //     .get("export-company")
      //     .then(response => {
      //         setTimeout(() => commit("set_busy", false), 3000);
      //     })
      //     .catch(error => {
      //     commit("set_busy", false);
      //     if (error.response) {
      //         if (error.response.data.message === "Unauthenticated.") {
      //             dispatch("reset").then(() => {
      //                 commit("resetState");
      //                 location.reload(true);
      //             });
      //         }
      //     }
        /*
              });
        */
  },

  getAllCompanies({ commit, dispatch }) {
    commit("set_busy", true);
    return axios
      .get("all_companies")
      .then(response => {
        commit("set_all_companies", response.data.companies);
        commit("set_busy", false);
      })
      .catch(error => {
        commit("set_busy", false);
        commit("set_all_companies", []);
          if (error.response) {
          if (error.response.data.message === "Unauthenticated.") {
            dispatch("reset").then(() => {
              commit("resetState");
              location.reload(true);
            });
          }
        }
      });
  },
  addUser({ commit, dispatch }, data) {
    commit("set_busy", true);
    return axios.post("auth/trial-registration-new", data);
  },

  getAdmins({ commit, dispatch }, data) {
    commit("set_busy", true);
    return axios.post("admins", data);
  },

  getAdminUsersLogs({ commit, dispatch }, data) {
    commit("set_busy", true);
    commit("toggle_loader", true);
    return axios
      .post("admins-logs", data)
      .then(response => {
        commit("toggle_loader", false);
        commit("set_admin_users_logs", response.data.admins_logs);
        commit("set_busy", false);
      })
      .catch(error => {
        commit("toggle_loader", false);
        commit("set_busy", false);
        commit("set_admin_users_logs", []);
        if (error.response) {
          if (error.response.data.message === "Unauthenticated.") {
            dispatch("reset").then(() => {
              commit("resetState");
              location.reload(true);
            });
          }
        }
      });
  },
  addRole({ commit, dispatch }, data) {
    commit("set_busy", true);
    return axios.post("role/store", data);
  },
  getRoleList({ commit, dispatch }, data) {
    commit("set_busy", true);
    return axios.get("all_user_roles", data);
  },
  deleteRole({ commit }, data) {
    commit("toggle_loader", true);
    return axios.post("role/detach-user", data);
  },
  getRolesPermission({ commit, dispatch }, data) {
    commit("set_busy", true);
    return axios.get("permissions", data);
  },
  getAdminRoleList({ commit, dispatch }, data) {
    commit("set_busy", true);
    return axios.get("all_admin_user_roles", data);
  },
  deleteAdminRole({ commit }, data) {
    commit("toggle_loader", true);
    return axios.post("role/detach-admin", data);
  },
  getUserRole({ commit, dispatch }, data) {
    commit("set_busy", true);
    return axios.get("users/roles", data);
  },
  addAdmin({ commit, dispatch }, data) {
    commit("set_busy", true);
    return axios.post("store/admin-user", data);
  },
  editPermission({ commit, dispatch }, data) {
    commit("set_busy", true);
    return axios.post("edit-permissions", data);
  },
  updateRole({ commit, dispatch }, data) {
    commit("set_busy", true);
    return axios.post("role/update", data);
  },
  editAdmin({ commit, dispatch }, data) {
    commit("set_busy", true);
    return axios.post("get/admin/roles", data);
  },
  updateAdmin({ commit, dispatch }, data) {
    commit("set_busy", true);
    return axios.post("update/admin/role", data);
  },
  updateUser({ commit, dispatch }, data) {
    commit("set_busy", true);
    return axios.post("update/user/role", data);
  },
  editUser({ commit, dispatch }, data) {
    commit("set_busy", true);
    return axios.post("get/user/roles", data);
  },
  archiveCompany(NULL, data) {
    return axios.post("company-archive", data);
  },
  restoreCompany(NULL, data) {
    return axios.post("company-restore", data);
  },
  archiveCompanyAdmin(NULL, data) {
    return axios.post("admin-company-archive", data);
  },
  restoreCompanyAdmin(NULL, data) {
    return axios.post("admin-company-restore", data);
  },
  suspendCompanyAdmin(NULL, data) {
    return axios.post("company-status-change", data);
  },
  archiveUser(NULL, data) {
    return axios.post("user-archive", data);
  },
  restoreUser(NULL, data) {
    return axios.post("user-restore", data);
  },

  getUsers({ commit, dispatch }, data) {
    commit("set_busy", true);
    commit("toggle_loader", true);
    return axios
      .post("users", data)
      .then(response => {
        commit("toggle_loader", false);
        commit("set_users", response.data.users);
        commit("set_pagination",response.data.pagination);
        commit("set_busy", false);
      })
      .catch(error => {
        commit("toggle_loader", false);
        commit("set_busy", false);
        commit("set_users", []);
        commit("set_pagination",[]);
          if (error.response) {
          if (error.response.data.message === "Unauthenticated.") {
            dispatch("reset").then(() => {
              commit("resetState");
              location.reload(true);
            });
          }
        }
      });
  },

  getUsersLogs({ commit, dispatch }, data) {
    commit("set_busy", true);
    commit("toggle_loader", true);
    return axios.post("users-logs", data).catch(error => {
      commit("toggle_loader", false);
      commit("set_busy", false);
      commit("set_users_logs", []);
      if (error.response) {
        if (error.response.data.message === "Unauthenticated.") {
          dispatch("reset").then(() => {
            commit("resetState");
            location.reload(true);
          });
        }
      }
    });
  },

  getScreenShotsChart({commit}){
    commit("set_busy", true);
    return axios.get('screenshots');
  },

  getScreenshots({ commit, dispatch },data) {
    commit("set_busy", true);
    commit("toggle_loader", true);
    return axios
      .get("screenshots/"+data.from+'/'+data.to)
      .then(response => {
        commit("set_screenshots", response.data.screenshots);
        commit("set_busy", false);
        commit("toggle_loader", false);
      })
      .catch(error => {
        commit("set_busy", false);
        commit("set_screenshots", []);
        if (error.response) {
          if (error.response.data.message === "Unauthenticated.") {
            dispatch("reset").then(() => {
              commit("resetState");
              location.reload(true);
            });
          }
        }
      });
  },

  getProjects({ commit, dispatch }, data) {
    commit("set_busy", true);
    commit("toggle_loader", true);
    return axios
      .post("projects", data)
      .then(response => {
        console.log(response)
        commit("set_projects", response.data.projects);
        commit("toggle_loader", false);
        commit("set_busy", false);
      })
      .catch(error => {
        commit("set_busy", false);
        commit("toggle_loader", false);
        commit("set_projects", []);
        if (error.response) {
          if (error.response.data.message === "Unauthenticated.") {
            dispatch("reset").then(() => {
              commit("resetState");
              location.reload(true);
            });
          }
        }
      });
  },


  // getCustomLogs({ commit, dispatch }, data) {
  //   commit("set_busy", true);
  //   commit("toggle_loader", true);
  //   return axios
  //       .post("user-custom-logs", data)
  //       .then(response => {
  //         commit("set_custom_log", response.data.user_logs);
  //         commit("toggle_loader", false);
  //         commit("set_busy", false);
  //       })
  //       .catch(error => {
  //         commit("set_busy", false);
  //         commit("toggle_loader", false);
  //         commit("set_custom_log", []);
  //         if (error.response) {
  //           if (error.response.data.message === "Unauthenticated.") {
  //             dispatch("reset").then(() => {
  //               commit("resetState");
  //               location.reload(true);
  //             });
  //           }
  //         }
  //       });
  // },

  getCustomLogs({ commit, dispatch }, data ) {
    return axios.post('user-custom-logs', data)
  },
  subScriptionStatus({ commit, dispatch }){
      return axios.get('subscription_status')
  },
  get_dashboard_data_chart({commit}) {
    commit("set_busy", true);
    return axios.get("dashboard");
  },

  get_dashboard_data({ commit, dispatch }) {
    commit("set_busy", true);
    commit("toggle_loader", true);
    return axios
      .get("dashboard")
      .then(response => {
        commit("set_dashboard", response.data.dashboard_widgets);
        commit("set_busy", false);
        commit("toggle_loader", false);
      })
      .catch(error => {
        commit("set_busy", false);
        commit("set_dashboard", []);
        // if (error.response) {
        //   if (error.response.data.message === "Unauthenticated.") {
        //     dispatch("reset").then(() => {
        //       commit("resetState");
        //       location.reload(true);
        //     });
        //   }
        // }
      });
  },
  updateCompany(NULL, data) {
    return axios.post("admin/company-update", data);
  },
  updateCompanyQty(NULL, data) {
    return axios.post("admin/company-update-qty", data);
  },
  updateCompanySubscription(NULL, data) {
    return axios.post("admin/company-update-subscription", data);
  },
  updateQuantity(NULL, data) {
    return axios.post("admin/company-update-subscription-quantity", data);
  },
  deleteCompany({ commit }, data) {
    commit("toggle_loader", true);
    return axios.post("company-delete", data);
  },
  changePassword({ commit }, data) {
    commit("set_busy", true);
    return axios.post("admin/password", data);
  },
  resetpassword(NULL, data) {
    return axios.post("auth/admin-password", data);
  },
  logout({ commit }) {
    return axios.post("auth/admin-logout").then(() => {
      commit("toggle_loader", false);
    });
  },
  get_dashboard_company(NULL, data){
      return axios.post("dashboard-companies", data);
  }
};

export default new Vuex.Store({
  state: initialState,
  mutations: mutations,
  actions: actions
});
