import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import JsonCSV from "vue-json-csv";
//===== Load module JS from node modules
import BootstrapVue from "bootstrap-vue";
import createPersistedState from "vuex-persistedstate";
import swal from "sweetalert";
import VeeValidate from "vee-validate";

//===== Load Utilities
import { notify, confirm_dialog, disabled_btn } from "@/assets/js/utils";

//===== Load Css from node modules
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

//===== Load custom Css
import "@/assets/css/style.css";
import "@/assets/css/font-awesome.min.css";
import "@/assets/css/responsive.css";

Vue.config.productionTip = false;

Vue.use(VeeValidate, {
  fieldsBagName: "inputs"
});
createPersistedState()(store);
Vue.use(BootstrapVue);
Vue.component("downloadCsv", JsonCSV);
Vue.notify = Vue.prototype.$notify = notify;
Vue.confirm_dialog = Vue.prototype.$confirm_dialog = confirm_dialog;
Vue.disabled_btn = Vue.prototype.$disabled_btn = disabled_btn;

new Vue({
  router,
  store,
  swal,
  render: h => h(App)
}).$mount("#app");
