<template>
    <div class="members-login-area d-flex">
        <div class="col-md-7 col-xl-6 login_form float-left">
            <img class="d-block m-auto logo-sticky w-100" src="@/assets/images/dark_logo.png">
            <div class="login-text">
                <p>Find out the correct hours and the progress of your projects using StaffTimerApp.</p>
            </div>
            <div class="col-md-12 float-left">
                <form @submit.prevent="loginForm" ref="loginForm" id="login_form" method="post" action="">
                    <div class="alert alert-success no-display text-center form-msg">

                    </div>
                    <div class="form-group email">
                        <input class="form-control" type="email" required v-model="email" data-vv-as="Email"
                               v-validate="'required|email'" name="email" placeholder="Email" autocomplete="username"
                        >
                        <span class="error-msg float-left mb-2 mt-2">{{ errors.first('email') }}</span>
                        <span v-if="login_errors.invalidEmail && email!==''" class="errorfield">The selected email is invalid.</span>
                    </div>
                    <div class="form-group pass">
                        <input class="form-control" type="password" required id="password" data-vv-as="Password"
                               v-validate="'required'" v-model="password" name="password" autocomplete="current-password"
                               placeholder="Password">
                        <span class="error-msg float-left mb-2 mt-2">{{ errors.first('password') }}</span>
                        <span v-if="login_errors.invalidPassword && password!==''" class="errorfield">Email or Password is incorrect</span>
                    </div>
                    <a class="float-right forget_password forget-pass hand" v-b-modal.forgotModal>Forget
                        Password?</a>
                    <div class="clearfix"></div>
                    <div class="clearfix"></div>
                    <div class="form-group">
                    <span class="float-left col-sm-6 mb-3">
                      <button ref="submit" :disabled="errors.any()" class="btn btn-theme" type="submit">SIGN IN</button>
                    </span>
<!--                        <a class="float-left col-sm-6" href="https://stafftimerapp.com/start-free-trial/"-->
<!--                           target="_blank">-->
<!--                            <BtnTheme type="button">SIGN UP</BtnTheme>-->
<!--                        </a>-->
                    </div>
                </form>
            </div>
        </div>
        <div class="login-bg col-sm-6 float-left">
            <div class="mt-5 st-quotes">
                <div class="login-heading-1st">BE PRODUCTIVE</div>
                <div class="login-heading-2nd">NOT JUST BUSY</div>
            </div>
            <div class="">
                <img class="img-clock-bg w-100" src="@/assets/images/blue_bg02.png">
            </div>
        </div>
        <ForgetPassword/>


        <!-- Resend Email modal -->
        <b-modal size="sm" ref="resend_email" id="resend_email">
            <div class="alert alert-success no-display text-center" id="forget_msg"></div>
            <form @submit.prevent="resend_email" ref="resend_form" id="resend_verify" method="post">
                <div class="form-group">
                    <input class="form-control" type="email" required name="email"
                           placeholder="Enter your email">
                </div>
                <btnTheme type="submit">SEND</btnTheme>
            </form>
        </b-modal>
    </div>
</template>

<script>
import ForgetPassword from "@/components/login/forgetModal";
import BtnTheme from "@/components/assets/BtnTheme";

import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, {
  siteKey: "6LfZMt8UAAAAAICgfYKVL-37Qa85xL9O_ILNPexn",
  loaderOptions: {
    useRecaptchaNet: true
  }
});

export default {
  name: "login",
  data() {
    return {
      email: null,
      password: null,
      login_errors: {
        invalidEmail: false,
        invalidPassword: false
      }
    };
  },
  components: { ForgetPassword, BtnTheme },
  beforeCreate() {
    this.$store.commit("toggle_loader", false);

    if (this.$store.state.user) {
      return this.$router.push("/dashboard");
    }
  },
  methods: {
    async loginForm() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("login");
      const recaptcha = this.$recaptchaInstance;

      if (this.email && this.password) {
        let formData = {
          email: this.email,
          password: this.password,
          recaptcha_token: token
        };
        let btn_ref = this.$refs.submit;
        this.$disabled_btn(btn_ref);

        this.$store
          .dispatch("login", formData)
          .then(() => {
            recaptcha.hideBadge();
            btn_ref.removeAttribute("disabled");
            btn_ref.innerHTML = btn_ref.getAttribute("original-text");
            return this.$router.push("/dashboard");
          })
          .catch(error => {
            recaptcha.showBadge();
            this.$store.commit("toggle_loader", false);
            btn_ref.removeAttribute("disabled");
            btn_ref.innerHTML = btn_ref.getAttribute("original-text");
            if (
              error.response.data.error &&
              error.response.data.error_msg === "Please verify your account."
            ) {
              this.$notify(
                "Error!",
                error.response.data.error_msg +
                  '<br/>Not Received Verification e-mail? <a data-toggle="modal" data-target="#resend_email" href="javascript:;" class="hand d-inline-block text-primary">Click Here</a>',
                "danger"
              );
              return;
            }
            if (error.response.data.error_msg) {
              this.$notify("Error!", error.response.data.error_msg);
              this.login_errors.invalidPassword = true;
            }
            if (
              error.response.data.errors &&
              error.response.data.errors.email
            ) {
              this.login_errors.invalidEmail = true;
            }
            if (error.response.status === 404) {
              return alert("Server not responding, try again in a moment");
            }
          });
      }
    },
    // loginForm() {
    //   if (this.email && this.password) {
    //     let formData = {
    //       email: this.email,
    //       password: this.password
    //     };
    //     let btn_ref = this.$refs.submit;
    //     this.$disabled_btn(btn_ref);
    //
    //     this.$store
    //       .dispatch("login", formData)
    //       .then(() => {
    //         btn_ref.removeAttribute("disabled");
    //         btn_ref.innerHTML = btn_ref.getAttribute("original-text");
    //         return this.$router.push("/dashboard");
    //       })
    //       .catch(error => {
    //         this.$store.commit("toggle_loader", false);
    //         btn_ref.removeAttribute("disabled");
    //         btn_ref.innerHTML = btn_ref.getAttribute("original-text");
    //         if (
    //           error.response.data.error &&
    //           error.response.data.error_msg === "Please verify your account."
    //         ) {
    //           this.$notify(
    //             "Error!",
    //             error.response.data.error_msg +
    //               '<br/>Not Received Verification e-mail? <a data-toggle="modal" data-target="#resend_email" href="javascript:;" class="hand d-inline-block text-primary">Click Here</a>',
    //             "danger"
    //           );
    //           return;
    //         }
    //         if (error.response.data.error_msg) {
    //             this.$notify(
    //                 "Error!",
    //                 error.response.data.error_msg
    //             );
    //           this.login_errors.invalidPassword = true;
    //         }
    //         if (
    //           error.response.data.errors &&
    //           error.response.data.errors.email
    //         ) {
    //           this.login_errors.invalidEmail = true;
    //         }
    //         if (error.response.status === 404) {
    //           return alert("Server not responding, try again in a moment");
    //         }
    //       });
    //   }
    // },
    resend_email() {
      let form_data = new FormData(this.$refs.resend_form);
      this.$store
        .dispatch("confirm_email", form_data)
        .then(response => {
          if (response.data.error === false) {
            this.$notify("Success!", response.data.error_msg, "success");
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped>
.members-login-area {
  background-color: #fff;
  float: left;
  width: 100%;
  position: relative;
}

.login_form {
  padding: 70px 11%;
  text-align: center;
  height: 100vh;
}

#login_form {
  margin-top: 30px !important;
  width: 100% !important;
  display: inline-block;
}

.img-clock-bg {
  left: -10px;
  position: absolute;
  top: 170px;
  width: 630px;
  height: calc(100vh - 170px);
}

.login-heading-1st {
  color: #24bafe;
  background: #fff;
  margin-bottom: 5px;
  margin-left: 0px;
  font-weight: bold;
  text-align: left;
  width: auto;
  line-height: normal;
  letter-spacing: 1px;
  display: inline-block;
  padding: 5px 25px 5px 5px;
  font-size: 2.8vmax;
}

.login-heading-2nd {
  font-size: 2.8vmax;
  color: #24bafe;
  background: #fff;
  margin-top: 10px;
  margin-left: 5%;
  font-weight: bold;
  text-align: left;
  line-height: normal;
  letter-spacing: 1px;
  display: inline-block;
  padding: 5px 25px 3px 5px;
}

.login-bg {
  background: #fff url("../assets/images/blue_bg01.png");
  background-size: cover;
  float: left;
  height: 100vh;
  overflow: hidden;
}

.login_form input.form-control {
  height: 40px;
  border: 1px solid #d6d6d6;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: unset;
}

.errorfield {
  color: #ff0000;
  font-size: 14px;
  text-align: left;
  display: block;
  padding: 5px 0 0;
}

.error-msg {
  color: tomato;
  font-size: 12px;
}

@media (max-width: 768px) {
  .login-text p {
    color: #000;
  }
}

.btn-theme {
  background-color: #012b7f;
  border-radius: 0px;
  float: left;
  height: 40px;
  width: calc(100%);
  margin-right: 10px;
}
</style>
<style>
.alert-info {
  background-color: pink !important;
}
</style>
