<template>
  <button :type="type" class="btn btn-theme"><slot></slot></button>
</template>

<script>
export default {
  name: "BtnTheme",
  props: ["type"]
};
</script>

<style scoped>
.btn-theme {
  background-color: #012b7f;
  border-radius: 0px;
  float: left;
  height: 40px;
  width: calc(100%);
  margin-right: 10px;
}
</style>
