<template>
 <b-collapse is-nav id="nav_collapse">
    <b-navbar-nav class="ml-xl-5 nav-main">
      <b-nav-item to="/dashboard" class="dashboard">Dashboard</b-nav-item>
        <b-nav-item to="/companies" class="companies" v-if="$store.state.permissions.includes('company-list')">Companies</b-nav-item>
        <b-nav-item to="/users" class="users" v-if="$store.state.permissions.includes('user-list')">Users</b-nav-item>
        <b-nav-item to="/projects" class="projects" v-if="$store.state.permissions.includes('project-list')">Projects</b-nav-item>
        <!--        <b-nav-item to="/videos" class="media" v-if="$store.state.permissions.includes('video-list')">Videos</b-nav-item>-->
        <!--        <b-nav-item to="/roles" class="roles" >UsersRoles</b-nav-item>-->
        <b-nav-item to="/admin-roles" class="admin-roles" v-if="$store.state.permissions.includes('admin-role-list')">Roles</b-nav-item>
        <b-nav-item to="/admins" class="admins" v-if="$store.state.permissions.includes('admin-list')">Admins</b-nav-item>
        <b-nav-item to="/logs" class="projects" v-if="$store.state.permissions.includes('custom-logs')">Logs</b-nav-item>
    </b-navbar-nav>
  </b-collapse>
</template>

<script>
export default {
  name: "NavbarLinks",

};
</script>

<style scoped>
#nav_collapse .nav-item .nav-link {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 20px;
  font-weight: 300;
}
#nav_collapse .nav-item .nav-link:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 26px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 10px;
}
.nav-item.projects .nav-link:before {
  content: "\f07b";
}
.nav-item.dashboard .nav-link:before {
  content: "\F0E4";
}
.nav-item.users .nav-link:before {
  content: "\F0C0";
}
.nav-item.companies .nav-link:before {
  content: "\F1AD";
}
.nav-item.media .nav-link:before {
    content: "\f008";
}
.nav-item.roles .nav-link:before {
    content: "\f007";
}
.nav-item.admin-roles .nav-link:before {
    content: "\f044";
}
.nav-item.admins .nav-link:before {
    content: "\f234";
}
@media (min-width: 1200px) {
  #nav_collapse .nav-item .nav-link {
    justify-content: center;
    flex-direction: column;
  }
}
@media (max-width: 1200px) {
  #nav_collapse .nav-item .nav-link:before {
    margin-bottom: 0;
    margin-right: 15px;
  }
  #nav_collapse .nav-item .nav-link {
    padding: 15px 0 15px 30px;
  }
}
</style>
