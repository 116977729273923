import Vue from "vue";
import Router from "vue-router";
import Login from "./views/Login.vue";
import auth from "./middleware/auth";
import PageNotFound from "./views/errors/PageNotFound.vue";
import ResetPassword from "./views/ResetPassword.vue";
import SetPassword from "./views/SetPassword.vue";
import Videos from "./views/Videos.vue";
import Logs from "./views/Logs.vue";


Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/login"
    },
    {
      path: "/login",
      name: "login",
      meta: { layout: "login" },
      component: Login
    },

    {
      path: "/dashboard",
      name: "dashboard",
      meta: {
        requiresAuth: true
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/DashboardTabs")
    },
    {
      path: "/companies",
      name: "companies",
      meta: {
        requiresAuth: true
      },
      component: () => import("./views/Companies.vue")
    },

    {
      path: "/users",
      name: "users",
      meta: {
        requiresAuth: true
      },
      component: () => import("./views/Users.vue")
    },

    {
      path: "/users_logs/:id",
      name: "users_logs",
      meta: {
        requiresAuth: true
      },
      component: () => import("./components/userlogs/UserLogs.vue")
    },
    {
      path: "/projects",
      name: "projects",
      meta: {
        requiresAuth: true
      },
      component: () => import("./views/Projects.vue")
    },
    {
      path: "/settings",
      name: "settings",
      meta: {
        requiresAuth: true
      },

      component: () => import("./views/Settings.vue")
    },
    {
      path: "/admins",
      name: "admins",
      meta: {
        requiresAuth: true
      },
      component: () => import("./views/Admins.vue")
    },
    {
      path: "/admins_logs",
      name: "admins_logs",
      meta: {
        requiresAuth: true
      },
      component: () => import("./components/userlogs/AdminUserLogs.vue")
    },
    {
      path: "/roles",
      name: "roles",
      meta: {
        requiresAuth: true
      },
      component: () => import("./views/roles.vue")
    },
    {
      path: "/admin-roles",
      name: "admin-roles",
      meta: {
        requiresAuth: true
      },
      component: () => import("./views/adminRoles.vue")
    },
    {
      path: "/password/reset/:token",
      name: "reset-password",
      meta: { layout: "login" },
      component: ResetPassword
    },
    {
      path : '/update/password/:token/:notification_id',
      name : 'update_password',
      meta: { layout: "login" },
      component: SetPassword

    },
    // {
    //   path: "/videos",
    //   name: "videos",
    //   meta: {
    //     requiresAuth: true
    //   },
    //   component: Videos
    // },
    {
      path: "/logs",
      name: "logs",
      meta: {
        requiresAuth: true
      },
      component: Logs
    },
    {
      path: "*",
      name: "Page Not Found",
      meta: { layout: "login" },
      component: PageNotFound
    },

  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!auth.user() || auth.user() === null) {
      next({
        path: "/login"
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
