<template>
    <div>
      <b-navbar toggleable="xl" type="dark" style="background-color: #10659c" class="py-xl-0 px-0 px-xl-3 navbar-wrapper">
        <b-navbar-toggle target="nav_collapse" class="px-xl-0 ml-3 ml-xl-0"></b-navbar-toggle>
        <b-navbar-brand to="/" class="py-0 mr-xl-5">
            <img src="@/assets/images/light_logo.png" width="200" alt="Logo image">
        </b-navbar-brand>
        <NavbarLinks />
        <NavbarDropdown />
      </b-navbar>
    </div>
</template>
<script>
import NavbarLinks from "./NavbarLinks";
import NavbarDropdown from "./NavbarDropdown";
export default {
  components: { NavbarLinks, NavbarDropdown },
  name: "Nabvar"
};
</script>
<style scoped>
</style>
