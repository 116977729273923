import axios from "axios";
import store from "../../store";
import { baseURL } from "./urls";

let axiosInstance = axios.create({
  baseURL: baseURL.API_URL,
  withCredentials: true,
  headers: {
    common: {
      "Content-Type": "application/json"
    },
  }
});

function getOnRejected() {
  return function(error) {
    function isUnauthorized() {
      return error.response.status === 401;
    }

    function isCSRFTokenMismatch() {
      return (
        error.response.status === 500 &&
        error.response.data.message === "CSRF token mismatch."
      );
    }

    if (isUnauthorized() || isCSRFTokenMismatch()) {
      localStorage.removeItem("token");
      store.dispatch("reset").then(() => {
        store.commit("resetState");
        location.reload(true);
      });
    }

    return Promise.reject(error);

  };
}

axiosInstance.interceptors.response.use(null, getOnRejected());

export default axiosInstance;
