<template>
    <b-modal centered size="sm" ref="forgotModal" id="forgotModal" title="Forgot Password" hide-footer >
      <div class="alert alert-success no-display text-center" id="forget_msg"></div>
      <form id="" @submit="onForgetSubmit" method="post">
        <div class="form-group">
          <input class="form-control" type="email" required name="forgotEmail" v-model="forgotEmail" data-vv-as="Email"
                 v-validate="'required|email'"
                 placeholder="Enter your email">
          <span class="error-msg float-left mb-2 mt-2">{{ errors.first('forgotEmail') }}</span>
        </div>
        <button ref="submitButton" :disabled="errors.any()" class="btn btn-theme" type="submit">SEND</button>
      </form>
    </b-modal>
</template>

<script>
import btnTheme from "@/components/assets/BtnTheme";

export default {
  name: "forgetModal",
  components: { btnTheme },
  data() {
    return {
      forgotEmail: ""
    };
  },
  methods: {
    onForgetSubmit(event) {
      event.preventDefault();
      let button = this.$refs.submitButton;
      let loadingText = "<i class='fa fa-spinner fa-spin'></i> Processing...";
      if (button.innerHTML !== loadingText) {
        button.setAttribute("original-text", button.innerHTML);
        button.innerHTML = loadingText;
        button.setAttribute("disabled", true);
      }
      let data = { email: this.forgotEmail };
      this.$store
        .dispatch("forgotPassword", data)
        .then(response => {
          this.forgotEmail = "";
          button.removeAttribute("disabled");
          button.innerHTML = button.getAttribute("original-text");
          this.$notify("", response.data.error_msg, "success");
          this.$refs.forgotModal.hide();
        })
        .catch(error => {
          button.removeAttribute("disabled");
          button.innerHTML = button.getAttribute("original-text");
          this.$notify("", error.response.data.error_msg, "success");
        });
    }
  }
};
</script>

<style scoped>
.btn-theme {
  background-color: #012b7f;
  border-radius: 0px;
  float: left;
  height: 40px;
  width: calc(100%);
  margin-right: 10px;
}
.error-msg {
  color: tomato;
  font-size: 12px;
}
</style>
