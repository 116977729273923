<template>
    <div>
        <div class="back-drop"></div>
        <b-progress class="app-progress" :value="100" :max="100" animated></b-progress>
    </div>
    <!--<div class="progress">-->
    <!--<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 100%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>-->
    <!--</div>-->
</template>

<script>
export default {
  name: "ProgressLoader",
  props: ["show"]
};
</script>

<style scoped>
.back-drop {
  height: 100vh;
  width: 100vw;
  opacity: 0.5;
  background: white;
  position: fixed;
  z-index: 1051;
}

.app-progress {
  height: 8px;
  border-radius: unset;
  position: fixed;
  top: 0px;
  z-index: 1052;
  width: 100%;
}
</style>
