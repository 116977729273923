<template>
  <div class="text-center w-25 m-auto pt-5">
    <img src="@/assets/images/404-error.png" alt="404 Not Found">
    <p class="error_text mt-2">404 Not Found</p>
    <a @click="$router.go(-1)" class="btn btn-link">Go Back</a>
  </div>
</template>

<script>
export default {
  name: "page-not-found"
};
</script>

<style scoped>
.error_text {
  font-size: larger;
  font-weight: bold;
}
.btn-link {
  color: #007bff !important;
}
.btn-link:hover {
  text-decoration: underline !important;
}
img {
  vertical-align: middle;
  border-style: none;
}
img {
  max-width: 100%;
}
</style>
