<template>
    <div>
        <progressloading class="parogress-wraper" v-show="show_loader"></progressloading>
        <component class="width" :is="layout">
            <router-view></router-view>
        </component>
    </div>
</template>

<script>
import { mapState } from "vuex";
import LoginLayout from "./layouts/LoginLayout";
import AppLayout from "./layouts/AppLayout";

import progressloading from "./components/Includes/ProgressLoader";
const default_layout = "app";

export default {
  name: "app",
  components: {
    "app-layout": AppLayout,
    "login-layout": LoginLayout,
    progressloading
  },

  computed: {
    ...mapState(["show_loader", "show_full_loader"]),
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    }
  }
};
require("jquery/dist/jquery.min");
require("bootstrap/dist/js/bootstrap.min");
</script>

<style>
.width{
  width: 100vw!important;
  overflow-x: hidden !important;
}
#updateModel___BV_modal_header_h5,
#updateSubscriptionModel___BV_modal_header_h5,
#updateQuantityModel___BV_modal_header_h5 {
  position: relative;
  padding-left: 43%;
  -webkit-transform: translate(-5%, 27%);
  -ms-transform: translate(-5%, 27%);
  transform: translate(-5%, 27%);
  white-space: nowrap;
}
#detailsModel___BV_modal_footer_,
#updateModel___BV_modal_footer_,
#updateSubscriptionModel___BV_modal_footer_,
#updateQuantityModel___BV_modal_footer_ {
  display: none !important;
}
h5.modal-title {
  text-align: center;
  justify-content: center;
  flex: 1;
}

/*#line-chart{*/
/*    width: 90vw!important;*/
/*    height: 900px!important;*/

/*}*/
</style>
