<template>
    <b-navbar-nav class="ml-auto flex-row align-items-center navbar-right">
        <b-nav-item-dropdown :text="user.name" right class="mr-3">
            <b-dropdown-item to="settings"><i class="fa fa-cog"></i> Settings</b-dropdown-item>
            <b-dd-divider></b-dd-divider>
            <b-dropdown-item href="javascript:;" @click="logOut"><i class="fa fa-sign-out"></i> Log Out</b-dropdown-item>
        </b-nav-item-dropdown>
    </b-navbar-nav>
</template>
<script>
export default {
  name: "NavbarDropdonw",
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    logOut() {
      this.$store.commit("toggle_loader", true);
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$store.dispatch("reset").then(() => {
            this.$store.commit("resetState");
            this.$store.commit("toggle_loader", false);
            location.reload(true);
          });
        })
        .catch(() => {});
    }
  }
};
</script>
<style scoped>
.dropdown-menu {
  border-radius: 2px;
  min-width: 220px;
}

.dropdown button:focus {
  box-shadow: none !important;
}

.dropdown-divider {
  margin: 0.3rem 0;
  border-color: #ddd;
}

.dropdown-title {
  cursor: default;
}

.dropdown-item.see-all {
  color: #007bff;
}

.dropdown-item.see-all.active {
  color: #fff;
}

.circle-logo {
  width: 35px;
  height: 35px;
}

.dropdown-item.see-all.active {
  color: #fff !important;
}

.companies-list {
  max-height: 100px;
  overflow: scroll;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
  color: #000;
}
</style>
