<template>
    <div>
        <b-row class="ml-1">
            <h3>All Logs</h3>
        </b-row>
        <div class="mt-2 d-flex flex-wrap justify-content-between">
            <div class="ml-2" >
                <label for="" class="mt-0 d-block font-weight-bold font-12">Records Per Page </label>
                <b-form-select class="hand width-select" :options="pageOptions" v-model="perPage"/>
            </div>
            <div class="ml-2" >
                <div>
                    <label for="" class="mt-0 font-weight-bold font-12">Date Range </label>
                    <div id="daterange" class="daterange d-flex" style="width:300px !important;">
                        <div class="calender-range"><i class="fa fa-calendar place"></i></div>
                        <span class="ml-2 daterange-dis font"></span>
                    </div>
                </div>
            </div>
            <div class="ml-2" >
                <label for="" class="mt-0 font-weight-bold font-12">Search </label>
                <b-input-group>
                    <b-form-input v-model="search" placeholder="Type here"/>
                    <b-input-group-append>
                        <b-btn class="hand" :disabled="!search" @click="search = ''">Clear</b-btn>
                    </b-input-group-append>
                </b-input-group>
            </div>
        </div>

        <b-row class="mt-3">
            <b-col>
                <b-table small responsive  show-empty empty-text="No Logs found for this date"
                         striped bordered hover fixed :items="items" :fields="fields"
                         :per-page="perPage" :filter="search" @filtered="onFiltered" class="break" id="companies_table">
                    <template v-slot:cell(Action)="cell">
                        <span>
                            <i @click.stop="logData(cell.item.data)" class="ml-2" size="sm"
                               title="View Log Detail" v-b-tooltip.hover><i class="fa fa-eye hand fa-style"></i></i>
                        </span>
                    </template>
                </b-table>
                <b-row>
                    <b-col md="12" class="my-1">
                        <b-pagination align="right"
                                v-model="currentPage"
                                :total-rows="pagination.total"
                                :per-page="pagination.per_page"
                                aria-controls="my-table"
                        ></b-pagination>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <div class="modal fade" ref="modal" id="log_data_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Logs Data</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <span>
                          <pre>
                            {{log_data}}
                          </pre>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from "vuex";
    import select2 from "../components/assets/select2";
    import moment from "moment";
    import $ from "jquery";
    import "daterangepicker/daterangepicker.js";
    import "daterangepicker/daterangepicker.css";

    export default {
        name: "Projects",
        components: { select2 },
        data() {
            return {
                company_id: "all",
                start: moment().subtract(1, "year"),
                end: moment(),
                start_date: moment()
                    .subtract(1, "year")
                    .format("YYYY-MM-DD"),
                end_date: moment().format("YYYY-MM-DD"),
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                totalRows: 0,
                search: null,
                items:[],
                log_data : null,
                fields: [
                    {
                        key:'log_type',
                        label: "Page Title",
                        sortable: true,
                        thStyle: {
                            width: "100px"
                        }
                    },
                    {
                        key:'IP',
                        label: "IP",
                        sortable: true,
                        thStyle: {
                            width: "60px"
                        }
                    },
                    {
                        key:'message',
                        label: "Message",
                        sortable: true,
                        thStyle: {
                            width: "100px"
                        }
                    },
                    {
                        key:'user_name',
                        label: "Action performed by",
                        sortable: true,
                        thStyle: {
                            width: "130px"
                        }
                    },
                    {
                        key:'date',
                        label: "Date",
                        sortable: true,
                        thStyle: {
                            width: "100px"
                        }
                    },
                    {
                        key:'member_name',
                        label: "Viewed Member",
                        sortable: true,
                        thStyle: {
                            width: "100px"
                        }
                    },
                    {
                        key:'status_code',
                        label: "Status Code",
                        sortable: true,
                        thStyle: {
                            width: "60px"
                        }
                    },

                    {
                        key:'Action',
                        label: "Action",
                        sortable: true,
                        thStyle: {
                            width: "60px"
                        }
                    },

                ]
            };
        },
        computed: {
          ...mapState(['pagination']),
          rows() {
            return this.items.length
          },
          pagination(){
            return this.$store.state.pagination;
          }
        },
      watch:{
        perPage(){
          return this.getDashboardData();
        },
        currentPage(){
          return this.getDashboardData();
        }
      },
        methods: {

            logData(data) {
                this.open = true
                let element = document.getElementById("log_data_modal");
                this.log_data = data
                $(element).modal('show')
            },

            dateRangeStart() {
                $(".daterange span").html(
                    this.start.format("MMM D, YYYY") +
                    " - " +
                    this.end.format("MMM D, YYYY")
                );
                $(".daterange").daterangepicker(
                    {
                        startDate: this.start,
                        endDate: this.end,
                        opens: "left",
                        ranges: {
                            "Last Year": [moment().subtract(1, "year"), moment()],
                            Today: [moment(), moment()],
                            Yesterday: [
                                moment().subtract(1, "days"),
                                moment().subtract(1, "days")
                            ],
                            "Last 7 Days": [moment().subtract(6, "days"), moment()],
                            "Last 30 Days": [moment().subtract(29, "days"), moment()],
                            "This Month": [moment().startOf("month"), moment().endOf("month")],
                            "Last Month": [
                                moment()
                                    .subtract(1, "month")
                                    .startOf("month"),
                                moment()
                                    .subtract(1, "month")
                                    .endOf("month")
                            ]
                        }
                    },
                    this.dateRangeChange
                );
            },
            dateRangeChange(start, end) {
                $(".daterange span")
                    .html(start.format("MMM D, YYYY") + " - " + end.format("MMM D, YYYY"))
                    .addClass("pl-2 pr-1");
                this.start_date = start.format("YYYY-MM-DD");
                this.end_date = end.format("YYYY-MM-DD");
                this.getDashboardData();
            },
            getDashboardData() {
                this.$store.commit("toggle_loader", true);
                let data = {
                  from     : this.start_date,
                  to       : this.end_date,
                  per_page : this.perPage,
                  page     : this.currentPage
                };
                this.$store
                    .dispatch("getCustomLogs", data).then((response) => {
                      this.items = response.data.user_logs
                      this.$store.commit("toggle_loader", false);
                      this.$store.commit('set_pagination',response.data.pagination)
                    })
                    .catch(() => {
                        this.$store.commit("toggle_loader", false);
                    });
            },
            itemsData(){
                return this.items;
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
        },
        mounted() {
            this.getDashboardData();
            this.dateRangeStart();
        },

    };
</script>

<style scoped>
.width-select{
    min-width: 130px!important;
}
</style>
<style>
    /* .select2-container {
        display: block !important;
        width: 100% !important;
    } */
</style>
