import swal from "sweetalert";
require("@/assets/js/bootstrap-notify");
import $ from "jquery";

export function confirm_dialog(text, buttons, callback) {
  swal({
    title: "Are you sure?",
    // content:true,
    text: text,
    icon: "warning",
    buttons: buttons,
    dangerMode: true
  }).then(willDelete => {
    if (willDelete) {
      if (buttons !== true) {
        callback(willDelete);
      } else {
        callback(true);
      }
    } else {
      callback(false);
    }
  });
}

export function notify(title, message, type) {
  $.notify(
    {
      // options
      //icon: 'fa fa-trash',
      title: "<strong>" + title + "</strong>",
      message: message
      //url: '',
      //target: '_blank'
    },
    {
      // settings
      element: "body",
      position: null,
      type: type,
      allow_dismiss: true,
      newest_on_top: true,
      showProgressbar: false,
      placement: {
        from: "top",
        align: "right"
      },
      offset: 20,
      spacing: 10,
      z_index: 99999,
      delay: 5000,
      timer: 1000,
      url_target: "_blank",
      mouse_over: null,
      animate: {
        enter: "animated fadeInDown",
        exit: "animated fadeOutUp"
      },
      onShow: null,
      onShown: null,
      onClose: null,
      onClosed: null,
      icon_type: "class",
      template:
        '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
        '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
        '<span data-notify="icon"></span> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        "</div>" +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        "</div>"
    }
  );
}

export function disabled_btn(event) {
  let button = event;
  let loadingText = "<i class='fa fa-spinner fa-spin'></i> Processing...";
  if (button.innerHTML !== loadingText) {
    button.setAttribute("original-text", button.innerHTML);
    button.innerHTML = loadingText;
    button.setAttribute("disabled", true);
  }
}
