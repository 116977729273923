<template>
    <div class="w-100 height">
        <Navbar/>
    </div>
</template>

<script>
import Navbar from "./Navbar";
import moment from "moment";

export default {
  name: "Header",
  data() {
    return {
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD")
    };
  },
  components: { Navbar },
  methods: {}
};
</script>

<style scoped>
.height{
  height: max-content !important;
}
.btn-menu {
  color: #fff;
  padding-left: 20px;
  line-height: 100px;
}

.small-menu ul {
  position: absolute;
  left: -40px;
  z-index: 2;
}
</style>
