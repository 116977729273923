<template>
    <div class="w-100">
        <div class="w-100 d-flex flex-column justify-content-center align-items-center" :style="{'background-image':'url(' + require('../assets/images/CreateCompanyBg.png') + ')',}">
        <div>
            <img class="d-block logo-size" src="../assets/images/st_logo_new.svg"/>
        </div>
        <div>
            <form
                    @submit.prevent="submit"
                    class="mb-3 set-password-form"
                    ref="loginForm"
                    id="reset_pwd"
                    method="post"
            >
                <div class="d-flex flex-column justify-content-center align-items-center">
                        
                        <div class="mt-2">
                                <span class="LeftText">Setup password to Use Account</span>
                        </div>
                        <div class="mt-3">
                                <span class="SecondText">Get full access to all our features for 10 days.</span>
                        </div>
                        <div class="mt-3">
                                <input
                                        class="form-control input_Feild password"
                                        @input="password_check"
                                        type="password"
                                        required
                                        id="password"
                                        data-vv-as="Password"
                                        v-validate="'required'"
                                        v-model="password"
                                        name="password"
                                        placeholder="Password"
                                        autocomplete="current-password"
                                />
                        </div>
                        <div class="mt-3">
                                <input
                                        class="form-control input_Feild password"
                                        type="password"
                                        required
                                        id="confirm_password"
                                        data-vv-as="Password"
                                        v-validate="'required|confirmed:password'"
                                        v-model="confirm_password"
                                        name="password"
                                        placeholder="Confirm Password"
                                        autocomplete="current-password"
                                />
                            <span v-if="error_data" style="color: red;font-size: 12px;margin-left: 19%;">{{this.error_data}}</span>
                        </div>
                        <div class="mt-5 mr-5">
                                <ul class="bulletList">
                                    <li :class="{ bulletList_password: has_lowercase }">
                                        At least one lower case alphabetic character
                                    </li>
                                    <li :class="{ bulletList_password: has_uppercase }">
                                        At least one upper case alphabetic character
                                    </li>
                                    <li :class="{ bulletList_password: has_special }">
                                        At least one special character, e.g., ! @ # ?
                                    </li>
                                    <li :class="{ bulletList_password: has_number }">
                                        At least one number
                                    </li>
                                    <li :class="{ bulletList_password: check_length_password }">
                                        Min. length at least 8 characters
                                    </li>
                                </ul>
                        </div>
                        <div class="mt-3">
                            <button
                                    ref="submit"
                                    class="btn btnContainer"
                                    type="submit"
                                    :disabled="login_button"
                            >
                                <span class="LoginBtnText">Sign up</span>
                            </button>
                        </div>
                        <div class=" my-3">
                                <span class="LowerText">By signing up, I agree to stafftimerapp Terms of Service andPrivacy Policy.</span>
                        </div>
                    
                </div>
            </form>
        </div>
        </div>
    </div>
</template>

<script>
    // import user_activityMixins from "../UserActivity/user_activityMixins";
    // Import component
    // Import stylesheet
    import { baseURL }   from "@/assets/js/urls";
    import { Validator } from "vee-validate";

    export default {
        name : "SetPassword",
        data () {
            return {
                eyeSlash : false,
                eye : true,
                email : null,
                isInit : false,
                isSignIn : false,
                password : null,
                confirm_password : null,
                login_errors : {
                    invalidEmail : false,
                    invalidPassword : false,
                },
                img_url : "",
                base64 : "",
                error : "",
                user_hash_id : "",
                has_number : false,
                has_lowercase : false,
                has_uppercase : false,
                has_special : false,
                has_special_number : false,
                has_lowercase_uppercase : false,
                check_length_password : false,
                login_button : true,
                error_data : false,
            };
        },

        mounted () {
            let app = this;
            app.$store.commit ( "toggle_loader", true );
            this.$store
                .dispatch ( "verify", this.$route.params.token )
                .then ( ( response ) => {
                    this.user_hash_id = response.data.auth_user.id;
                    this.$notify ( "", response.data.error_msg, "success" );
                    app.$store.commit ( "toggle_loader", false );
                } )
                .catch ( ( error ) => {
                    if ( error.response.data.error === true ) {
                        this.$notify ( "Error!", error.response.data.error_msg, "danger" );
                        app.$store.commit ( "toggle_loader", false );
                    }
                } );

            // if ( this.$store.state.auth.user !== null ) {
            //     this.$store.dispatch ( 'custom/user_logout' ).then ( () => {
            //         this.$store.commit ( 'resetState' );
            //         this.$store.dispatch ( 'reset' )
            //     } ).catch ( () => {
            //         this.$store.commit ( 'resetState' );
            //         this.$store.dispatch ( 'reset' )
            //     } )
            // }
        },
        computed : {
            base_url () {
                return baseURL.API_URL;
            },
        },
        methods : {
            password_check : function () {
                this.has_number = /\d/.test ( this.password );
                this.has_lowercase = /[a-z]/.test ( this.password );
                this.has_uppercase = /[A-Z]/.test ( this.password );
                this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test ( this.password );
                this.has_number = this.has_number === true;
                this.has_special = this.has_special === true;
                this.has_lowercase = this.has_lowercase === true;
                this.has_uppercase = this.has_uppercase === true;
                this.check_length_password = this.password.length >= 8;
                if (
                    this.has_number === true &&
                    this.has_special === true &&
                    this.has_lowercase === true &&
                    this.has_uppercase === true &&
                    this.password.length >= 8
                ) {
                    this.login_button = false;
                }
                else if (this.has_number === false ||
                    this.has_special === false ||
                    this.has_lowercase === false ||
                    this.has_uppercase === false ||
                    this.form.password.length < 8)
                {
                    this.login_button = true;
                }
            },
            password_length ( val ) {
                return val.length >= 8;
            },
            show_hide_Password () {
                if ( document.getElementById ( "password" ).type === "password" ) {
                    this.eye = false;
                    this.eyeSlash = true;
                    document.getElementById ( "password" ).type = "text";
                } else {
                    this.eyeSlash = false;
                    this.eye = true;
                    document.getElementById ( "password" ).type = "password";
                }
            },

            show_hide_Password2 () {
                if ( document.getElementById ( "confirm_password" ).type === "password" ) {
                    this.eye = false;
                    this.eyeSlash = true;
                    document.getElementById ( "confirm_password" ).type = "text";
                } else {
                    this.eyeSlash = false;
                    this.eye = true;
                    document.getElementById ( "confirm_password" ).type = "password";
                }
            },

            submit : function () {
                this.updatePassword ();
            },

            onCaptchaExpired : function () {
                this.$refs.recaptcha.reset ();
            },

            updatePassword : function () {
                this.$store.commit ( "toggle_loader", true );
                let data = {
                    hash_id : this.user_hash_id,
                    password : this.password,
                    password_confirmation : this.confirm_password,
                    // g_recaptcha_response  : recaptchaToken
                };
                this.$store
                    .dispatch ( "updatePassword", data )
                    .then ( ( response ) => {
                        if ( response.data.error === false ) {
                            this.$notify ( "", response.data.error_msg, "success" );
                            this.$store.commit ( "custom/toggle_loader", false );
                            return this.$router.push("/dashboard");

                        }
                    } )
                    .catch(error => {
                        this.$store.commit("toggle_loader", false);
                        if (
                            error.response.data.error &&
                            error.response.data.error_msg === "Please verify your account."
                        ) {
                            this.$notify(
                                "Error!",
                                error.response.data.error_msg +
                                '<br/>Not Received Verification e-mail? <a data-toggle="modal" data-target="#resend_email" href="javascript:;" class="hand d-inline-block text-primary">Click Here</a>',
                                "danger"
                            );
                            return;
                        }
                        if (error.response.data.errors) {
                            this.$notify("Error!", error.response.data.errors.password[0], "danger");
                            this.login_errors.invalidPassword = true;
                        }
                        if (
                            error.response.data.errors &&
                            error.response.data.errors.email
                        ) {
                            this.login_errors.invalidEmail = true;
                        }
                        if (error.response.status === 404) {
                            return alert("Server not responding, try again in a moment");
                        }
                    });
            },
        },
    };
</script>
<style scoped>
.set-password-form{
    background: white!important;
    width: 430px!important;
    height: 550px;
    overflow: scroll!important;
}
    .w-100 {
        width: 80%;
        height: 110vh;
        overflow: scroll!important;
    }
    .logo-size{
        width: 350px!important;
        height: 130px!important;
    }
    .mainContainer {
        background-color: black;
    }

    .bg-image {
        background-color: Lightgrey;
    }

    .rightSide-color {
        background-color: blue;
    }

    .login-button {
        height: 48px;
        width: 118px;
        border-radius: 3px;
        background-color: #2758f6;
        box-shadow: 0 6px 21px 1px #ccd6f5;
    }

    .LoginBtnText {
        color: #ffffff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.06px;
        line-height: 16px;
    }

    .BgContainer {
        height: 589px;
        border-radius: 8px;
        background-color: #ffffff;
        box-shadow: inset 0 1px 3px 0 rgba(147, 147, 147, 0.17),
        0 3px 10px 0 rgba(214, 214, 214, 0.47);
    }

    .LeftText {
        color: #001788;
        font-family: "Gochi Hand";
        font-size: 28px;
        letter-spacing: 0.27px;
        line-height: 48px;
    }

    .RightText {
        color: #ff3366;
        font-family: "Gochi Hand";
        font-size: 28px;
        letter-spacing: 0.27px;
        line-height: 48px;
    }

    .SecondText {
        height: 22px;
        width: 366px;
        color: #001788;
        font-family: Poppins;
        font-size: 14px;
        letter-spacing: 0.13px;
        line-height: 20px;
        text-align: center;
    }

    .input_Feild {
        box-sizing: border-box;
        height: 58px;
        width: 300px;
        border: 1px solid #c5ddff;
        border-radius: 31px;
        background-color: #ffffff;
    }

    ::placeholder {
        color: #81a2d3 !important;
        font-family: Poppins;
        font-size: 18px !important;
        letter-spacing: 0.23px !important;
        line-height: 25px !important;
    }

    .bulletList {
        color: #81a2d3;
        font-family: Poppins;
        font-size: 14px;
        letter-spacing: 0.18px;
        line-height: 23px;
        margin-top: -5px;
    }

    .btnContainer {
        height: 60px;
        width: 200px;
        border-radius: 32px;
        background-color: #2758f6;
        box-shadow: 0 2px 4px 0 rgba(175, 175, 175, 0.5);
    }

    .SignupWithGoogle {
        height: 24px;
        color: #001788;
        font-family: Poppins;
        font-size: 16px;
        letter-spacing: 0.13px;
        line-height: 24px;
        text-align: center;
    }

    .LowerText {
        height: 22px;
        color: #647ea5;
        font-family: Poppins;
        font-size: 12px;
        letter-spacing: 0.18px;
        line-height: 23px;
        text-align: center;
    }

    .go-back {
        height: 22px;
        width: 77px;
        color: #6985dc;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.23px;
        line-height: 22px;
        text-align: center;
    }

    .image {
        background-repeat: no-repeat;
    }

    .BackIcon {
        transform: rotate(180deg);
    }

    .bottom-text {
        padding-left: 10px;
    }

    @media (min-width: 1680px) and (max-width: 1910px) {
        .Screen1680plus1 {
            padding-left: 13% !important;
        }

        .Screen1680plus2 {
            padding-left: 10% !important;
        }

        .Screen1680plus3 {
            padding-left: 10% !important;
        }

        .Screen1680plus4 {
            padding-left: 15% !important;
        }
    }

    .bulletList_password {
        color: #011788;
        font-family: Poppins;
        font-size: 14px;
        letter-spacing: 0.18px;
        line-height: 23px;
    }

    .errorfield {
        color: #ff0000;
        font-size: 12px;
        display: flex;
        margin-left: 23%;
    }

    ul {
        list-style: none;
    }

    ul li:before {
        content: "✓";
    }
</style>
